@import url("https://fonts.googleapis.com/css?family=Rubik:500,700&display=swap");

/* Generic Styles */
body {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  overflow-x: hidden;
  overflow-y: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Devices */
@media all and (min-width: 0px) and (max-width: 479px) {
  body {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #ffbc00;
  }

  .App-footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    z-index: 10;
    /* padding: 0.8em 0em; */
    justify-content: space-between;
  }

  .App-info-links {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-decoration-line: none;
    margin: 1em 2em;
    justify-content: space-between;
  }

  .App-header {
    position: relative;
    width: 100%;
    background-color: #ffbc00;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-info-text {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    color: white;
    text-decoration: none;
    font-size: 0.7em;
  }

  .App-small-logo {
    width: 1em;
    margin: 0.2em 0em;
  }

  .App-logo-black {
    animation: App-logo-spin infinite 1s ease-in-out;
    /* transition-delay: 2s; */
    animation-delay: 2s;
    width: 52px;
    position: absolute;
    top: 52px;
  }

  .App-logo {
    width: 17em;
    pointer-events: none;
    position: relative;
  }

  .App {
    width: 100%;
  }

  .App-header {
    width: 100%;
    background-color: #ffbc00;
  }

  .App-slogan {
    width: 350px;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    text-align: center;
    font-size: 1.02em;
    margin: 40px 0px;
  }

  .App-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* position: relative; */
    padding-top: 115px;
  }

  .App-store-container {
    display: flex;
    flex-direction: column;
    /* align-items: left; */
    /* justify-content: left; */
  }

  .App-store-btn {
    /* margin-right: 18px; */
    background-color: black;
    max-width: 300px;
    border-radius: 100px;
    text-decoration: none;
    color: white;
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    -webkit-filter: drop-shadow(-5px 5px 10px #000000);
    filter: drop-shadow(-5px 5px 10px #000000);
    padding: 4px 40px;
  }

  .App-fa-icon {
    font-size: 2.2em;
  }

  .App-store-btn:hover {
    background-color: white;
  }

  .App-store-btn:hover .App-big-txt {
    color: black;
  }
  .App-store-btn:hover .App-small-txt {
    color: black;
  }
  .App-store-btn:hover .App-fa-icon {
    color: black;
  }

  .App-small-txt {
    font-size: 10px;
    margin-left: 10px;
    text-transform: uppercase;
    color: white;
    font-family: "Lucida Grande", sans-serif;
  }

  .App-big-txt {
    font-size: 18px;
    color: white;
    text-transform: capitalize;
  }

  #App-image {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  #App-image div {
    display: block;
  }

  .App-blue-screen {
    display: none;
  }

  .App-red-screen {
    display: none;
    /* margin-bottom: 200px;
    width: 75%;
    margin-top: 30px;
    height: auto;
    align-self: center;
    -webkit-filter: drop-shadow(-10px 10px 20px #7f474c);
    filter: drop-shadow(-10px 10px 20px #7f474c); */
  }

  .App-purple-screen {
    display: none;
  }

  .App-green-screen {
    display: none;
  }

  .App-fade {
    margin-bottom: 200px;
    width: 75%;
    margin-top: 30px;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #7f474c);
    filter: drop-shadow(-10px 10px 20px #7f474c);
  }

  .slide-container {
    width: 100%;
    margin: auto;
    text-align: center;
  }
}

/* Tablet small */
@media all and (min-width: 480px) and (max-width: 735px) {
  .App-footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    z-index: 10;
    padding: 0.8em 0em;
    justify-content: space-between;
  }

  .App-info-links {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration-line: none;
    margin: 0em 2em;
  }

  .App-info-text {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    color: white;
    text-decoration: none;
    font-size: 0.5em;
  }

  .App-small-logo {
    width: 0.85em;
  }

  .App-logo-black {
    animation: App-logo-spin infinite 1s ease-in-out;
    /* transition-delay: 2s; */
    animation-delay: 2s;
    width: 45px;
    position: absolute;
    right: -15px;
    top: -30px;
  }

  .App-logo {
    width: 340px;
    pointer-events: none;
    position: relative;
  }

  .App {
    position: relative;
    width: 100%;
  }

  .App-header {
    position: relative;
    width: 100%;
    background-color: #ffbc00;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-slogan {
    width: 360px;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 26px;
    margin: 40px 0px;
  }

  .App-text-container {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    position: relative;
  }

  .App-store-container {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
  }

  .App-store-btn {
    margin-right: 18px;
    background-color: black;
    max-width: 300px;
    border-radius: 100px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-filter: drop-shadow(-5px 5px 10px #000000);
    filter: drop-shadow(-5px 5px 10px #000000);
    padding: 4px 20px;
  }

  .App-fa-icon {
    font-size: 0.9em;
  }

  .App-store-btn:hover {
    background-color: white;
  }

  .App-store-btn:hover .App-big-txt {
    color: black;
  }
  .App-store-btn:hover .App-small-txt {
    color: black;
  }
  .App-store-btn:hover .App-fa-icon {
    color: black;
  }

  .App-small-txt {
    font-size: 9px;
    margin-left: 10px;
    text-transform: uppercase;
    color: white;
    font-family: "Lucida Grande", sans-serif;
  }

  .App-big-txt {
    font-size: 15px;
    color: white;
    text-transform: capitalize;
  }

  #App-image {
    position: relative;
    width: 100%;
    height: 100%;
  }

  #App-image div {
    display: block;
  }

  .App-blue-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 66%;
    top: 22px;
    width: 50%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004fb4);
    filter: drop-shadow(-10px 10px 20px #004fb4);
  }

  .App-red-screen {
    position: absolute;
    transform: rotate(-30deg);
    right: 66%;
    bottom: 302px;
    width: 50%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #7f474c);
    filter: drop-shadow(-10px 10px 20px #7f474c);
  }

  .App-purple-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: -37%;
    top: -211px;
    width: 50%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #a599e9);
    filter: drop-shadow(-10px 10px 20px #a599e9);
  }

  .App-green-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 80%;
    bottom: 190px;
    width: 50%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004050);
    filter: drop-shadow(-10px 10px 20px #004050);
  }

  .App-fade {
    display: none;
  }
}

/* Tablet large */
@media all and (min-width: 736px) and (max-width: 959px) {
  .App-footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    z-index: 10;
    padding: 0.8em 0em;
    justify-content: space-between;
  }

  .App-info-links {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration-line: none;
    margin: 0em 2em;
  }

  .App-info-text {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    color: white;
    text-decoration: none;
    font-size: 14px;
  }

  .App-small-logo {
    width: 3vh;
  }

  .App-logo-black {
    animation: App-logo-spin infinite 1s ease-in-out;
    /* transition-delay: 2s; */
    animation-delay: 2s;
    width: 45px;
    position: absolute;
    right: 10px;
    top: -25px;
  }

  .App-logo {
    width: 360px;
    pointer-events: none;
    position: relative;
  }

  .App {
    position: relative;
    width: 100%;
  }

  .App-header {
    position: relative;
    width: 100%;
    background-color: #ffbc00;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-slogan {
    width: 410px;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 28px;
    margin: 40px 0px;
  }

  .App-text-container {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    position: relative;
  }

  .App-store-container {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
  }

  .App-store-btn {
    margin-right: 20px;
    background-color: black;
    max-width: 300px;
    border-radius: 100px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-filter: drop-shadow(-5px 5px 10px #000000);
    filter: drop-shadow(-5px 5px 10px #000000);
    padding: 4px 22px;
  }

  .App-fa-icon {
    font-size: 0.9em;
  }

  .App-store-btn:hover {
    background-color: white;
  }

  .App-store-btn:hover .App-big-txt {
    color: black;
  }
  .App-store-btn:hover .App-small-txt {
    color: black;
  }
  .App-store-btn:hover .App-fa-icon {
    color: black;
  }

  .App-small-txt {
    font-size: 9px;
    margin-left: 10px;
    text-transform: uppercase;
    color: white;
    font-family: "Lucida Grande", sans-serif;
  }

  .App-big-txt {
    font-size: 17px;
    color: white;
    text-transform: capitalize;
  }

  #App-image {
    position: relative;
    width: 100%;
    height: 100%;
  }

  #App-image div {
    display: block;
  }

  .App-blue-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 60%;
    top: 39px;
    width: 42%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004fb4);
    filter: drop-shadow(-10px 10px 20px #004fb4);
  }

  .App-red-screen {
    position: absolute;
    transform: rotate(-30deg);
    right: 63%;
    bottom: 350px;
    width: 42%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #7f474c);
    filter: drop-shadow(-10px 10px 20px #7f474c);
  }

  .App-purple-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: -25%;
    top: -305px;
    width: 42%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #a599e9);
    filter: drop-shadow(-10px 10px 20px #a599e9);
  }

  .App-green-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 77%;
    bottom: 71px;
    width: 42%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004050);
    filter: drop-shadow(-10px 10px 20px #004050);
  }

  .App-fade {
    display: none;
  }
}

/* Desktop Small */
@media all and (min-width: 960px) and (max-width: 1199px) {
  .App-footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    z-index: 10;
    padding: 0.8em 0em;
    justify-content: space-between;
  }

  .App-info-links {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration-line: none;
    margin: 0em 2em;
  }

  .App-info-text {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    color: white;
    text-decoration: none;
    font-size: 16px;
  }

  .App-small-logo {
    width: 3vh;
  }

  .App-logo-black {
    animation: App-logo-spin infinite 1s ease-in-out;
    /* transition-delay: 2s; */
    animation-delay: 2s;
    width: 45px;
    position: absolute;
    right: 10px;
    top: -25px;
  }

  .App-logo {
    width: 360px;
    pointer-events: none;
    position: relative;
  }

  .App {
    position: relative;
    width: 100%;
  }

  .App-header {
    position: relative;
    width: 100%;
    background-color: #ffbc00;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-slogan {
    width: 410px;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 28px;
    margin: 40px 0px;
  }

  .App-text-container {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    position: relative;
  }

  .App-store-container {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
  }

  .App-store-btn {
    margin-right: 20px;
    background-color: black;
    max-width: 300px;
    border-radius: 100px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-filter: drop-shadow(-5px 5px 10px #000000);
    filter: drop-shadow(-5px 5px 10px #000000);
    padding: 4px 22px;
  }

  .App-fa-icon {
    font-size: 0.9em;
  }

  .App-store-btn:hover {
    background-color: white;
  }

  .App-store-btn:hover .App-big-txt {
    color: black;
  }
  .App-store-btn:hover .App-small-txt {
    color: black;
  }
  .App-store-btn:hover .App-fa-icon {
    color: black;
  }

  .App-small-txt {
    font-size: 9px;
    margin-left: 10px;
    text-transform: uppercase;
    color: white;
    font-family: "Lucida Grande", sans-serif;
  }

  .App-big-txt {
    font-size: 17px;
    color: white;
    text-transform: capitalize;
  }

  #App-image {
    position: relative;
    width: 100%;
    height: 100%;
  }

  #App-image div {
    display: block;
  }

  .App-blue-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 60%;
    top: 20px;
    width: 38%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004fb4);
    filter: drop-shadow(-10px 10px 20px #004fb4);
  }

  .App-red-screen {
    position: absolute;
    transform: rotate(-30deg);
    right: 63%;
    bottom: 350px;
    width: 38%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #7f474c);
    filter: drop-shadow(-10px 10px 20px #7f474c);
  }

  .App-purple-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: -18%;
    top: -421px;
    width: 38%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #a599e9);
    filter: drop-shadow(-10px 10px 20px #a599e9);
  }

  .App-green-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 72%;
    bottom: -35px;
    width: 38%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004050);
    filter: drop-shadow(-10px 10px 20px #004050);
  }

  .App-fade {
    display: none;
  }
}

/* Desktop medium */
@media all and (min-width: 1200px) and (max-width: 1399px) {
  .App-footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    z-index: 10;
    padding: 0.8em 0em;
    justify-content: space-between;
  }

  .App-info-links {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration-line: none;
    margin: 0em 2em;
  }

  .App-info-text {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    color: white;
    text-decoration: none;
    font-size: 16px;
  }

  .App-small-logo {
    width: 3vh;
  }

  .App-logo-black {
    animation: App-logo-spin infinite 1s ease-in-out;
    /* transition-delay: 2s; */
    animation-delay: 2s;
    width: 50px;
    position: absolute;
    right: -15px;
    top: -30px;
  }

  .App-logo {
    width: 400px;
    pointer-events: none;
    position: relative;
  }

  .App {
    position: relative;
    width: 100%;
  }

  .App-header {
    position: relative;
    width: 100%;
    background-color: #ffbc00;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-slogan {
    width: 400px;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 30px;
    margin: 40px 0px;
  }

  .App-text-container {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    position: relative;
  }

  .App-store-container {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
  }

  .App-store-btn {
    margin-right: 25px;
    background-color: black;
    max-width: 300px;
    border-radius: 100px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-filter: drop-shadow(-5px 5px 10px #000000);
    filter: drop-shadow(-5px 5px 10px #000000);
    padding: 4px 25px;
  }

  .App-store-btn:hover {
    background-color: white;
  }

  .App-store-btn:hover .App-big-txt {
    color: black;
  }
  .App-store-btn:hover .App-small-txt {
    color: black;
  }
  .App-store-btn:hover .App-fa-icon {
    color: black;
  }

  .App-small-txt {
    font-size: 9px;
    margin-left: 10px;
    text-transform: uppercase;
    color: white;
    font-family: "Lucida Grande", sans-serif;
  }

  .App-big-txt {
    font-size: 18px;
    color: white;
    text-transform: capitalize;
  }

  #App-image {
    position: relative;
    width: 100%;
    height: 100%;
  }

  #App-image div {
    display: block;
  }

  .App-blue-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 60%;
    top: 10px;
    width: 36%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004fb4);
    filter: drop-shadow(-10px 10px 20px #004fb4);
  }

  .App-red-screen {
    position: absolute;
    transform: rotate(-30deg);
    right: 59%;
    bottom: 350px;
    width: 36%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #7f474c);
    filter: drop-shadow(-10px 10px 20px #7f474c);
  }

  .App-purple-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: -10%;
    top: -466px;
    width: 36%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #a599e9);
    filter: drop-shadow(-10px 10px 20px #a599e9);
  }

  .App-green-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 70%;
    bottom: -25px;
    width: 36%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004050);
    filter: drop-shadow(-10px 10px 20px #004050);
  }

  .App-fade {
    display: none;
  }
}

/* Desktop large */
@media all and (min-width: 1400px) {
  .App-footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    z-index: 10;
    padding: 0.8em 0em;
    justify-content: space-between;
  }

  .App-info-links {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration-line: none;
    margin: 0em 2em;
  }

  .App-info-text {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    color: white;
    text-decoration: none;
    font-size: 16px;
  }

  .App-small-logo {
    width: 3vh;
  }

  .App-logo-black {
    animation: App-logo-spin infinite 1s ease-in-out;
    /* transition-delay: 2s; */
    animation-delay: 2s;
    max-width: 60px;
    position: absolute;
    right: -45px;
    top: -30px;
  }

  .App-logo {
    max-width: 440px;
    pointer-events: none;
    position: relative;
  }

  .App {
    position: relative;
    width: 100%;
  }

  .App-header {
    position: relative;
    width: 100%;
    background-color: #ffbc00;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-slogan {
    max-width: 440px;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 32px;
    margin: 40px 0px;
  }

  .App-text-container {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    position: relative;
  }

  .App-store-container {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
  }

  .App-store-btn {
    margin-right: 30px;
    background-color: black;
    max-width: 300px;
    border-radius: 100px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-filter: drop-shadow(-5px 5px 10px #000000);
    filter: drop-shadow(-5px 5px 10px #000000);
    padding: 4px 27px;
  }

  .App-store-btn:hover {
    background-color: white;
  }

  .App-store-btn:hover .App-big-txt {
    color: black;
  }
  .App-store-btn:hover .App-small-txt {
    color: black;
  }
  .App-store-btn:hover .App-fa-icon {
    color: black;
  }

  .App-small-txt {
    font-size: 10px;
    margin-left: 10px;
    text-transform: uppercase;
    color: white;
    font-family: "Lucida Grande", sans-serif;
  }

  .App-big-txt {
    font-size: 20px;
    color: white;
    text-transform: capitalize;
  }

  #App-image {
    position: relative;
    width: 100%;
    height: 100%;
  }

  #App-image div {
    display: block;
  }

  .App-blue-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 59%;
    top: 10px;
    width: 33%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004fb4);
    filter: drop-shadow(-10px 10px 20px #004fb4);
  }

  .App-red-screen {
    position: absolute;
    transform: rotate(-30deg);
    right: 58%;
    bottom: 370px;
    width: 33%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #7f474c);
    filter: drop-shadow(-10px 10px 20px #7f474c);
  }

  .App-purple-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: -5%;
    top: -460px;
    width: 33%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #a599e9);
    filter: drop-shadow(-10px 10px 20px #a599e9);
  }

  .App-green-screen {
    display: block;
    position: absolute;
    transform: rotate(-30deg);
    left: 70%;
    bottom: -25px;
    width: 33%;
    height: auto;
    -webkit-filter: drop-shadow(-10px 10px 20px #004050);
    filter: drop-shadow(-10px 10px 20px #004050);
  }

  .App-fade {
    display: none;
  }
}
